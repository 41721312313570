import React from "react";
import CredoBanner from "../../assets/credoBanner.png";

const BeforeResult = () => {
  return (
    <div>
      <a href="https://www.credomgt.com/" target="blank">
        <img
          style={{
            width: "100%",
            height: "auto",
            alignSelf: "center",
            objectFit: "cover",
          }}
          src={CredoBanner}
          alt="Credo banners"
        />
      </a>
    </div>
  );
};

export default BeforeResult;
