import React, { useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  TextField,
  Card,
  CardContent,
  Button,
  Grid,
  Paper,
  Fab,
  Alert,
  AlertTitle,
} from "@mui/material";
import BeforeResult from "./components/beforeresult/BeforeResult";
import Header from "./components/header/Header";

const App = () => {
  // General Calculator Logic
  // const [taxtypevalue, setTaxtypevalue] = useState("both");
  const [selectPropertyRates, setSelectPropertyRates] = useState(true);
  const [selectRentalIncomeTax, setSelectRentalIncomeTax] = useState(true);
  const [currency, setCurrency] = useState("UGX");
  const [currencyPlaceholder, setCurrencyPlaceholder] =
    useState("Value in UGX");
  const [rentalIncomeTaxThreshold, setRentalIncomeTaxThreshold] =
    useState(2820000);
  const [showResult, setShowResult] = useState(false);
  const [showPropertyRateResult, setShowPropertyRateResult] = useState(false);
  const [showRentalIncomeTaxResult, setShowRentalIncomeTaxResult] =
    useState(false);
  const [showBothResult, setShowBothResult] = useState(false);
  const [showNoRentalIncomeTaxToPay, setShowNoRentalIncomeTaxToPay] =
    useState(false);

  const handleTaxTypeRadioChange = (event) => {
    if (event.target.value === "both") {
      setSelectPropertyRates(true);
      setSelectRentalIncomeTax(true);
      setShowResult(false);
      setShowBothResult(false);
      setShowPropertyRateResult(false);
      setShowRentalIncomeTaxResult(false);
    } else if (event.target.value === "property-rates") {
      setSelectPropertyRates(true);
      setSelectRentalIncomeTax(false);
      setShowResult(false);
      setShowBothResult(false);
      setShowPropertyRateResult(false);
      setShowRentalIncomeTaxResult(false);
      setShowNoRentalIncomeTaxToPay(false);
    } else if (event.target.value === "rental-income-tax") {
      setSelectRentalIncomeTax(true);
      setSelectPropertyRates(false);
      setShowResult(false);
      setShowBothResult(false);
      setShowPropertyRateResult(false);
      setShowRentalIncomeTaxResult(false);
      setShowNoRentalIncomeTaxToPay(false);
    } else {
      console.log("Error");
    }
  };
  const handleCurrencyTypeRadioChange = (event) => {
    if (event.target.value === "ugx") {
      setCurrencyPlaceholder("Value in UGX");
      setCurrency("UGX");
      setRentalIncomeTaxThreshold(2820000);
    } else {
      setCurrencyPlaceholder("Value in USD");
      setCurrency("USD");
      setRentalIncomeTaxThreshold(724);
    }
  };

  // Property Rates Calculation Logic
  const [propertyValue, setPropertyValue] = useState(0);
  const [municipalPropertyRate, setMunicipalPropertyRate] = useState(0.04);
  const [municipalPropertyType, setMunicipalPropertyType] = useState(0.78);
  const propertyRateTaxEstimate =
    municipalPropertyRate * (municipalPropertyType * propertyValue);
  const handleMunicipalPropertyRateChange = (event) => {
    event.preventDefault();
    if (event.target.value === "0.04") {
      setMunicipalPropertyRate(0.04);
    } else if (event.target.value === "0.06") {
      setMunicipalPropertyRate(0.06);
    } else if (event.target.value === "0.12") {
      setMunicipalPropertyRate(0.12);
    } else {
      console.log("Property Rate type Unknown");
    }
  };
  const handleMunicipalPropertyTypeChange = (event) => {
    event.preventDefault();
    if (event.target.value === "residential") {
      setMunicipalPropertyType(0.78);
    } else if (event.target.value === "commercial") {
      setMunicipalPropertyType(0.7);
    } else {
      setShowResultError(true);
    }
  };
  /* const municipalities = [
    { key: 1, municipality: "Kampala" },
    { key: 2, municipality: "Kiira" },
    { key: 3, municipality: "Entebbe" },
  ]; */

  // Rental Income Tax Calculation Logic
  const [individualProperty, setIndividualProperty] = useState(false);
  const [grossAnnualIncome, setGrossAnnualIncome] = useState(0);
  const [annualExpensesAndLosses, setAnnualExpensesAndLosses] = useState(0);

  const handleOwnershipTypeChange = (event) => {
    event.preventDefault();
    if (event.target.value === "individual") {
      setIndividualProperty(true);
      setShowResult(false);
      setShowNoRentalIncomeTaxToPay(false);
    } else if (event.target.value === "company") {
      setIndividualProperty(false);
      setShowResult(false);
      setShowNoRentalIncomeTaxToPay(false);
    } else {
      console.log("Ownership type Unknown");
    }
  };

  const individualRentalIncomeTax = 0.12 * grossAnnualIncome;
  const companyRentalIncomeTax =
    0.3 * (grossAnnualIncome - annualExpensesAndLosses);

  /*const totalIndividualTax =
    individualRentalIncomeTax + propertyRateTaxEstimate;
  const totalCompanyTax = companyRentalIncomeTax + propertyRateTaxEstimate; */

  // Calculator Submission Logic
  const [showResultError, setShowResultError] = useState(false);
  const handleCalcSubmit = (event) => {
    event.preventDefault();
    if (selectPropertyRates && selectRentalIncomeTax) {
      if (grossAnnualIncome > rentalIncomeTaxThreshold) {
        setShowResult(true);
        setShowBothResult(true);
        setShowPropertyRateResult(false);
        setShowRentalIncomeTaxResult(false);
        setShowResultError(false);
      } else if (
        (grossAnnualIncome <= rentalIncomeTaxThreshold &&
          individualRentalIncomeTax > 0) ||
        (grossAnnualIncome <= rentalIncomeTaxThreshold &&
          companyRentalIncomeTax > 0)
      ) {
        setShowResult(true);
        setShowResultError(false);
        setShowBothResult(false);
        setShowNoRentalIncomeTaxToPay(true);
        setShowPropertyRateResult(true);
        setShowRentalIncomeTaxResult(false);
      } else {
        setShowResultError(true);
      }
    } else if (selectPropertyRates && !selectRentalIncomeTax) {
      if (propertyRateTaxEstimate > 0) {
        setShowResult(true);
        setShowBothResult(false);
        setShowResultError(false);
        setShowPropertyRateResult(true);
        setShowRentalIncomeTaxResult(false);
      } else {
        setShowResultError(true);
      }
    } else if (!selectPropertyRates && selectRentalIncomeTax) {
      if (grossAnnualIncome > rentalIncomeTaxThreshold) {
        setShowResult(true);
        setShowResultError(false);
        setShowBothResult(false);
        setShowRentalIncomeTaxResult(true);
        setShowPropertyRateResult(false);
      } else if (
        (grossAnnualIncome <= rentalIncomeTaxThreshold &&
          individualRentalIncomeTax > 0) ||
        (grossAnnualIncome <= rentalIncomeTaxThreshold &&
          companyRentalIncomeTax > 0)
      ) {
        setShowResult(true);
        setShowResultError(false);
        setShowBothResult(false);
        setShowPropertyRateResult(false);
        setShowRentalIncomeTaxResult(false);
        setShowNoRentalIncomeTaxToPay(true);
      } else {
        setShowResultError(false);
      }
    } else {
      setShowResultError(true);
    }
  };

  const handleCalcReset = (event) => {
    event.preventDefault();
    setShowResult(false);
    setShowResultError(false);
    setShowBothResult(false);
    setShowRentalIncomeTaxResult(false);
    setShowPropertyRateResult(false);
    setShowNoRentalIncomeTaxToPay(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}><Header /></Grid>
      <Grid item xs={12} md={6}>
        <form onSubmit={handleCalcSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5">Enter your Property's Details</Typography>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                What kind of property tax do you want to calculate
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleTaxTypeRadioChange}
                defaultValue="both"
                helperText="Your Property's Annual Expenses and Losses"
              >
                <FormControlLabel
                  value="rental-income-tax"
                  control={<Radio />}
                  label="Rental Income Tax"
                />
                <FormControlLabel
                  value="property-rates"
                  control={<Radio />}
                  label="Property Rates"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Currency to Use
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="ugx"
                onChange={handleCurrencyTypeRadioChange}
              >
                <FormControlLabel value="ugx" control={<Radio />} label="UGX" />
                <FormControlLabel value="usd" control={<Radio />} label="USD" />
              </RadioGroup>
            </FormControl>
            {selectPropertyRates ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Municipal Property Rate</Typography>
                <Typography variant="caption">
                  This is the amount of money that you pay to the municipality
                  where your property is located. It depends on your property's
                  estimated value.
                </Typography>

                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Type of Rental Property
                  </FormLabel>
                  <Typography variant="caption" color="text.secondary">
                    Residential properties have a 22% threshold whereas
                    Commercial properties have a 30% threshold.
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="residential"
                    onChange={handleMunicipalPropertyTypeChange}
                  >
                    <FormControlLabel
                      value="residential"
                      control={<Radio />}
                      label="Residential"
                    />
                    <FormControlLabel
                      value="commercial"
                      control={<Radio />}
                      label="Commercial"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Property Rate to be used
                  </FormLabel>
                  <Typography variant="caption" color="text.secondary">
                    Different municipalities use different percentages. These
                    are the most commonly used percentages. Choose one of these
                    to estimate how much you would pay if any of these were
                    applied to you.
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="0.04"
                    onChange={handleMunicipalPropertyRateChange}
                  >
                    <FormControlLabel
                      value="0.04"
                      control={<Radio />}
                      label="4%"
                    />
                    <FormControlLabel
                      value="0.06"
                      control={<Radio />}
                      label="6%"
                    />
                    <FormControlLabel
                      value="0.12"
                      control={<Radio />}
                      label="12%"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  id="filled-textarea"
                  label="Estimated Property Value"
                  placeholder={currencyPlaceholder}
                  type="number"
                  variant="filled"
                  helperText="Your Property's Estimated value as provided by your municipal council. (Or you can make a personal estimate)"
                  onChange={(e) => setPropertyValue(e.target.value)}
                />
              </Box>
            ) : null}
            {selectRentalIncomeTax ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Rental Income Tax</Typography>
                <Typography variant="caption">
                  This is paid to URA. It depends on your property's annual
                  rental income.
                </Typography>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    What kind of property ownership is the property under?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleOwnershipTypeChange}
                    defaultValue="company"
                  >
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      label="Individual Ownership"
                    />
                    <FormControlLabel
                      value="company"
                      control={<Radio />}
                      label="Company Ownership"
                    />
                    <Typography variant="caption" color="text.secondary">
                      Individually owned properties are subject to a tax of 12%
                      of the gross annual rental income of the property whereas
                      company owned properties are subject to a tax of 30% of
                      the difference between the property's gross annual income
                      and the expenses and losses.
                    </Typography>
                  </RadioGroup>
                </FormControl>
                <TextField
                  id="filled-textarea"
                  label="Annual Rental Income"
                  helperText="Your Property's Gross Annual Income"
                  placeholder={currencyPlaceholder}
                  onChange={(e) => setGrossAnnualIncome(e.target.value)}
                  type="number"
                  variant="filled"
                />
                {!individualProperty ? (
                  <TextField
                    id="filled-textarea"
                    label="Annual Expenses and Losses"
                    onChange={(e) => setAnnualExpensesAndLosses(e.target.value)}
                    placeholder={currencyPlaceholder}
                    helperText="This should not exceed 50% of your property's annual rental income"
                    type="number"
                    variant="filled"
                  />
                ) : null}
              </Box>
            ) : null}
            {showResultError ? (
              <Alert severity="error">
                Error! Please review your inputs and try again
              </Alert>
            ) : null}
            {!showResult ? (
              <Button type="submit" variant="contained">
                Calculate
              </Button>
            ) : null}

            {showResult ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={handleCalcReset}
              >
                <>
                  <Fab fullWidth type="reset" variant="extended">
                    Reset Calculator
                  </Fab>
                  <Alert severity="success">
                    Success! Your Tax Estimate is Complete
                  </Alert>
                </>
              </div>
            ) : null}
          </Box>
        </form>
      </Grid>
      <Grid xs={12} md={6}>
        {!showResult ? (
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" align="center">
                Your Result Will Appear Here
              </Typography>
              <Card elevation={3}>
                <CardContent>
                  <Typography gutterBottom>
                    This Property Tax Estimator is provided by Credo Management
                    (U) Ltd. It is meant to give you a rough estimate about how
                    much you are supposed to pay in property taxes in Uganda. It
                    is only an estimation tool.
                  </Typography>
                </CardContent>
              </Card>
              <BeforeResult />
            </Box>
          </Grid>
        ) : null}
        {showBothResult ? (
          <Grid item>
            <Paper elevation={3}>
              <Typography variant="h5" align="center">
                Here is your Property Tax estimate
              </Typography>

              {propertyRateTaxEstimate > 2 ? (
                <>
                  <Typography>
                    Your Property Rate Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {propertyRateTaxEstimate}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    The Property Rate is a fee paid annually to the municipal
                    council where your property is found. It is based on your
                    property's value. The property value is estimated by your
                    municipal council. Different municipalities use different
                    percentages to calculate this fee.
                  </Typography>
                </>
              ) : null}
              {individualProperty ? (
                <>
                  <Typography>
                    Your Individual Rental Income Tax Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {individualRentalIncomeTax}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Individually owned properties are subject to a tax of 12% of
                    the gross annual rental income of the property whereas
                    company owned properties are subject to a tax of 30% of the
                    difference between their Gross Annual Rental Income and
                    their Annual Expenses and Losses. There is no tax on any
                    annual rental income (for both individuals and companies)
                    amount that falls below the {currency}
                    {rentalIncomeTaxThreshold} threshold.
                  </Typography>
                </>
              ) : null}
              {!individualProperty ? (
                <>
                  <Typography>
                    Your Company Rental Income Tax Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {companyRentalIncomeTax}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Individually owned properties are subject to a tax of 12% of
                    the gross annual rental income of the property whereas
                    company owned properties are subject to a tax of 30% of the
                    difference between their Gross Annual Rental Income and
                    their Annual Expenses and Losses. There is no tax on any
                    annual rental income (for both individuals and companies)
                    amount that falls below the {currency}
                    {rentalIncomeTaxThreshold} threshold.
                  </Typography>
                </>
              ) : null}
            </Paper>
          </Grid>
        ) : null}
        {showPropertyRateResult ? (
          <Grid item>
            <Paper elevation={3}>
              <Typography variant="h5" align="center">
                Here is your Property Tax estimate
              </Typography>

              {propertyRateTaxEstimate > 2 ? (
                <>
                  <Typography>
                    Your Property Rate Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {propertyRateTaxEstimate}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    The Property Rate is a fee paid annually to the municipal
                    council where your property is found. It is based on your
                    property's value. The property value is estimated by your
                    municipal council. Different municipalities use different
                    percentages to calculate this fee.
                  </Typography>
                </>
              ) : null}
            </Paper>
          </Grid>
        ) : null}
        {showRentalIncomeTaxResult ? (
          <Grid item>
            <Paper elevation={3}>
              <Typography variant="h5" align="center">
                Here is your Property Tax estimate
              </Typography>
              {individualProperty ? (
                <>
                  <Typography>
                    Your Individual Rental Income Tax Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {individualRentalIncomeTax}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Individually owned properties are subject to a tax of 12% of
                    the gross annual rental income of the property whereas
                    company owned properties are subject to a tax of 30% of the
                    difference between their Gross Annual Rental Income and
                    their Annual Expenses and Losses. There is no tax on any
                    annual rental income (for both individuals and companies)
                    amount that falls below the {currency}
                    {rentalIncomeTaxThreshold} threshold.
                  </Typography>
                </>
              ) : null}
              {!individualProperty ? (
                <>
                  <Typography>
                    Your Company Rental Income Tax Estimate is{" "}
                    <Typography variant="h6">
                      {currency} {companyRentalIncomeTax}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Individually owned properties are subject to a tax of 12% of
                    the gross annual rental income of the property whereas
                    company owned properties are subject to a tax of 30% of the
                    difference between their Gross Annual Rental Income and
                    their Annual Expenses and Losses. There is no tax on any
                    annual rental income (for both individuals and companies)
                    amount that falls below the {currency}
                    {rentalIncomeTaxThreshold} threshold.
                  </Typography>
                </>
              ) : null}
            </Paper>
          </Grid>
        ) : null}
        <Grid item>
          {showNoRentalIncomeTaxToPay ? (
            <Alert severity="success">
              <AlertTitle>Lucky You!</AlertTitle>
              You have no annual rental income tax to pay to URA because your
              gross annual income is below the — <strong>Tax Threshold</strong>
            </Alert>
          ) : null}
        </Grid>
        {showResult ? <BeforeResult /> : null}
      </Grid>
      )
    </Grid>
  );
};
// JSX For the Total Tax
/* {individualProperty ? (
              <Typography>
                Your Total Individual Property Tax Estimate is {currency}{" "}
                {totalIndividualTax}
              </Typography>
            ) : null}
            {!individualProperty ? (
              <Typography>
                Your Total Company Property Tax Estimate is {currency}{" "}
                {totalCompanyTax}
              </Typography>
            ) : null} */

export default App;
